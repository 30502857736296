import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { generate as randomWords } from 'random-words';
import * as d3 from 'd3';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const subdomains = [
  'crypto',
  'defi',
  'nft',
  'dao',
  'web3',
  'hodl',
  'moon',
  'lambo',
];

const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];

const DynamicBackground = () => {
  const canvasRef = useRef(null);

  // Function to add interconnected words representing the blockchain
  const createInterconnectedWords = (context, words, width, height) => {
    // Create particles representing words
    const particles = words.map((word) => ({
      x: Math.random() * width,
      y: Math.random() * height,
      vx: (Math.random() - 0.5) * 1,
      vy: (Math.random() - 0.5) * 1,
      text: word,
      radius: 550 // Approximate size for the bounding area of each word
    }));

    // Update and draw particles
    const updateParticles = () => {
      context.clearRect(0, 0, width, height);
      particles.forEach((p1, idx) => {
        p1.x += p1.vx;
        p1.y += p1.vy;

        // Bounce off the edges
        if (p1.x < 0 || p1.x > width) p1.vx *= -1;
        if (p1.y < 0 || p1.y > height) p1.vy *= -1;

        // Draw word
        context.fillStyle = "#FFFFFF";
        context.font = "16px sans-serif";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(p1.text, p1.x, p1.y);

        // Draw connections
        particles.slice(idx + 1).forEach((p2) => {
          const distance = Math.hypot(p1.x - p2.x, p1.y - p2.y);
          if (distance < 100) {
            context.beginPath();
            context.moveTo(p1.x, p1.y);
            context.lineTo(p2.x, p2.y);
            context.strokeStyle = `rgba(255, 255, 255, ${1 - distance / 100})`;
            context.stroke();
          }
        });
      });

      requestAnimationFrame(updateParticles);
    };

    updateParticles();
  };



  useEffect(() => {
    const generatedWords = randomWords(30).map(word => `${word}`);//.bullrun.eth
    const allWords = [...subdomains, ...generatedWords];

    const width = window.innerWidth;
    const height = window.innerHeight;

    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    const color = d3.scaleOrdinal(d3.schemeTableau10);

    const nodes = allWords.map((word, index) => ({
      id: index,
      text: word,
      r: 20 + word.length * 2,
      x: Math.random() < 0.5 ? Math.random() * width * 0.2 : width * 0.8 + Math.random() * width * 0.2,
      y: Math.random() < 0.5 ? Math.random() * height * 0.2 : height * 0.8 + Math.random() * height * 0.2,
      color: colors[Math.floor(Math.random() * colors.length)],
      glow: Math.random() < 0.3 // Randomly assign glow effect
    }));

    const simulation = d3.forceSimulation(nodes)
      .alphaTarget(0.3) // stay hot
      .velocityDecay(0.1) // low friction
      .force("x", d3.forceX().strength(0.01))
      .force("y", d3.forceY().strength(0.01))
      .force("collide", d3.forceCollide().radius(d => d.r + 1).iterations(3))
      .force("charge", d3.forceManyBody().strength((d, i) => i ? 0 : -width * 2 / 3))
      .on("tick", ticked);

    function ticked() {
      context.clearRect(0, 0, width, height);
      context.save();
      context.translate(width / 2, height / 2);

      for (let i = 1; i < nodes.length; ++i) {
        const d = nodes[i];
        context.beginPath();
        context.moveTo(d.x + d.r, d.y);
        context.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
        context.fillStyle = d.color;
        context.fill();
        if (d.glow) {
          context.shadowBlur = 20;
          context.shadowColor = d.color;
        } else {
          context.shadowBlur = 0;
        }

        // Add text inside the circle
        context.fillStyle = "#fff"; // Text color, adjust as needed
        context.font = "16px sans-serif"; // Font size and style
        context.textAlign = "center"; // Center the text horizontally
        context.textBaseline = "middle"; // Center the text vertically
        context.fillText(d.text, d.x, d.y); // Position the text in the center of the circle
      }

      context.restore();
    }

    // Usage: call this function inside the useEffect after the canvas setup
    
    if (Math.random() < 0.5) {
      createInterconnectedWords(context, allWords, width, height);
    }

    d3.select(context.canvas)
      .on("touchmove", event => event.preventDefault())
      .on("pointermove", pointermoved);

    function pointermoved(event) {
      const [x, y] = d3.pointer(event);
      nodes[0].fx = x - width / 2;
      nodes[0].fy = y - height / 2;
    }

    // function handleMouseMove(event) {
    //   const [x, y] = d3.pointer(event, canvas);
      
    //   // Very small radius and strong repulsion
    //   simulation.force('mouse', d3.forceRadial(5, x, y).strength(5))
    //     .alpha(1)
    //     .restart();
    // }

    // d3.select(canvas)
    //   .on("mousemove", handleMouseMove);

    // Handle window resize
    function handleResize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      simulation.force("center", d3.forceCenter(canvas.width / 2, canvas.height / 2));
      simulation.alpha(1).restart();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      simulation.stop();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <canvas ref={canvasRef} style={{width: '100%', height: '100%'}}></canvas>
    </Container>
  );
};

export default DynamicBackground;