// src/components/MetaMaskLogin.js
import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import MetaMaskAuth from '../MetaMaskAuth';
import { toast } from 'react-toastify';

const MetaMaskLogin = ({ setSigner, signer }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const [logged , setLogged] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    const metaMaskAuth = new MetaMaskAuth();

    try {
      const signer = await metaMaskAuth.handleSignup();
      // setUser(metaMaskAuth.auth.currentUser);
      setLogged(true);
      setSigner(signer);
      //welcome user with a toast message
      toast.success(`Welcome to bullrun.eth`, { position: 'top-center' });
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
// console.log(user)
  return (
    <div>
      {signer ? (
        <Typography variant="body1">Welcome {signer?.address}</Typography>
      ) : (
          <Button onClick={handleLogin} disabled={loading} variant="contained" color="secondary">
          {loading ? <CircularProgress size={24} /> : 'Connect with MetaMask'}
        </Button>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default MetaMaskLogin;
