// import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { BrowserProvider } from 'ethers';
const ethers = require("ethers")

class MetaMaskAuth {
  address='';
  signer = null;
  // baseRoute = 
  // baseRoute = 'https://us-central1-rapagram-442fc.cloudfunctions.net'
  baseRoute = window.location.hostname === 'localhost' ? 'http://localhost:5001/rapagram-442fc/us-central1' : 'https://us-central1-rapagram-442fc.cloudfunctions.net'

  constructor() {
    // this.auth = getAuth(); 
    // Then in your connectWallet function:
    this.provider = new BrowserProvider(window.ethereum);
  }

  async connectWallet() {
      try {
        let signer = null;

        let provider;
        if (window.ethereum == null) {

          // If MetaMask is not installed, we use the default provider,
          // which is backed by a variety of third-party services (such
          // as INFURA). They do not have private keys installed,
          // so they only have read-only access
          console.log("MetaMask not installed; using read-only defaults")
          provider = ethers.getDefaultProvider()
          return await provider.getSigner();
        } else {

          // Connect to the MetaMask EIP-1193 object. This is a standard
          // protocol that allows Ethers access to make all read-only
          // requests through MetaMask.
          provider = new ethers.BrowserProvider(window.ethereum)

          // It also provides an opportunity to request access to write
          // operations, which will be performed by the private key
          // that MetaMask manages for the user.
          signer = await provider.getSigner();
          console.log(signer)
          return signer
        }
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    }

  async generateMessageToSign(address, nonce) {
    // Customize this message with your app's details
    const message = `Sign this message to sign up on hiphop.box with address: ${address} Nonce: ${nonce}`;

    return message
  }

  async handleSignup() {
    try {
      const signer = await this.connectWallet();
      this.signer = signer;
      this.address = await signer.getAddress();
      console.log('signer===', signer, this.address)
      // Fetch nonce from your backend
      // const nonce = await this.fetchNonceFromBackend(this.address);

      // const message = await this.generateMessageToSign(this.address, nonce);
      // const signature = await signer.signMessage(message);

      // Send signed message and address to backend for verification and user creation
      // const customToken = await this.createUserOnBackend(this.address, signature);

      // Sign in with Firebase using the custom token
      // await signInWithCustomToken(this.auth, customToken);
      console.log("User signed up and logged in!");
      return signer
    } catch (error) {
      console.error("Signup error:", error);
      // Handle errors appropriately (e.g., display user-friendly messages)
    }
  }

  // Placeholder for backend communication - implement these in your Firebase Function
  // async fetchNonceFromBackend(address) {
  //   try {
  //     const response = await fetch(this.baseRoute + '/getNonce', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ address }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch nonce');
  //     }

  //     const data = await response.json();
  //     return data.nonce;
  //   } catch (error) {
  //     console.error("Error fetching nonce:", error);
  //     throw error;
  //   }
  // }

  // async createUserOnBackend(address, signature) {
  //   try {
  //     const response = await fetch(this.baseRoute+'/createUser', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ address, signature }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to create user');
  //     }

  //     const data = await response.json();
  //     return data.customToken;
  //   } catch (error) {
  //     console.error("Error creating user:", error);
  //     throw error;
  //   }
  // }
}

export default MetaMaskAuth;
