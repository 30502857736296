import React from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';

const featureList = [
  { title: 'Exclusive Community', description: 'Join a network of forward-thinking crypto enthusiasts.' },
  { title: 'Web3 Identity', description: 'Establish your presence in the decentralized web.' },
  { title: 'Easy Integration', description: 'Seamlessly connect with dApps and Web3 services.' },
];

const Features = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h2" gutterBottom align="center" sx={{ mt: 0 }}> {/* Removed top margin */}
        Why .bullrun.eth is Amazing
      </Typography>
      <Grid container spacing={3}>
        {featureList.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
              <Typography variant="h5" gutterBottom>
                {feature.title}
              </Typography>
              <Typography variant="body1">
                {feature.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;