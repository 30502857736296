import React, { useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { TextField, Button, Box, InputAdornment, Tooltip, CircularProgress } from '@mui/material';
import abi from '../abi.json';
const { ethers, JsonRpcProvider, formatEther, parseEther } = require("ethers")

const globalProvider = new JsonRpcProvider("https://mainnet.infura.io/v3/48a6912e53bf46b897c762eb6674fc8c");
const contractAddress = "0x0FEFCe3804Ae06fB800139F4f5807AD5F527356B";

const StyledInput = styled(TextField)`
  width: 300px;
  margin-right: 10px;
  
  .MuiOutlinedInput-root {
    background-color: rgba(255, 255, 255, 0.1);
    height: 56px; // Match the height of the button
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  .MuiOutlinedInput-input {
    color: white;
    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
      opacity: 1;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 56px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  transition: all 0.3s ease-in-out;

  &:hover {
    background: linear-gradient(45deg, #FE8B8B 30%, #FFAE73 90%);
    transform: translateY(-2px);
    box-shadow: 0 6px 10px 4px rgba(255, 105, 135, .4);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 3px 1px rgba(255, 105, 135, .3);
  }
`;

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}


const SearchBox = (
  {
    setWord,
    word,
    signer
  }
) => {
  const [inputValue, setInputValue] = useState(word);
  const [error, setError] = useState(null);
  const [available, setAvailable] = useState(false);
  const [price, setPrice] = useState(3);
  const [loading, setLoading] = useState(false)

  const bullrun = new ethers.Contract(contractAddress, abi, signer ?? globalProvider);

  // Define the debounced function
  const debouncedCheck = useRef(
    debounce(async (value) => {
      console.log('Checking subdomain:', value);
      try {
        const result = await bullrun.checkTakenByPrice(String(value));
        const price = ethers.formatEther(result);
        console.log('Is subdomain taken:', result, price);
        if (price === '99.0') {
          setAvailable(false);
          setPrice(0);
        } else {
          setAvailable(true);
          setPrice(price);
        }
      } catch (error) {
        console.error('Error checking subdomain:', error);
      } finally {
        setLoading(false);
      }
    }, 300) // 300 milliseconds debounce delay
  ).current;

  const handleInputChange = (event) => {
    setLoading(true)
    const value = event.target.value;
    setInputValue(value);
    setWord(value);
    debouncedCheck(value);
  };

  const handleRegistration = async () => {
    console.log('Searching for:', inputValue);
    let accountBalance = await globalProvider.getBalance(signer.address);
    accountBalance = formatEther(accountBalance);
    console.log('Account balance:', accountBalance);

    if (parseFloat(accountBalance) < parseFloat(price)) {
      return toast.error('Sorry but You dont have enough funds to get this subdomain');
    }

    try {
      console.log('Estimating gas...');
      const estimatedGas = await bullrun.estimateGas.registerSubdomain(inputValue);
      console.log('Estimated gas:', estimatedGas.toString());

      console.log('Getting gas price...');
      const gasPrice = await signer.getGasPrice();
      console.log('Gas price:', gasPrice.toString());

      console.log('Calculating price...');
      const totalPrice = formatEther(estimatedGas.mul(gasPrice));
      console.log('Price:', totalPrice);

      console.log('Checking user balance...');
      if (parseFloat(accountBalance) < parseFloat(totalPrice)) {
        return toast.error('Insufficient funds in your wallet. Please fund your wallet and try again.');
      }

      console.log('Registering subdomain...');
      const tx = await bullrun.registerSubdomain(inputValue, { gasLimit: estimatedGas, gasPrice: gasPrice });
      console.log('Transaction hash:', tx.hash);

      console.log('Waiting for transaction to be mined...');
      await tx.wait();
      console.log('Transaction mined successfully!');

      toast.success('Subdomain registered successfully!');
    } catch (error) {
      console.error('Error registering subdomain:', error);

      if (error.code === 'INSUFFICIENT_FUNDS') {
        toast.error('Insufficient funds to register subdomain. Please fund your wallet and try again.');
      } else if (error.code === 'INVALID_ARGUMENT') {
        toast.error('Invalid JSON RPC request. Please check your input and try again.');
      } else if (error.code === 'UNSUPPORTED_OPERATION') {
        toast.error('Method not found. Please check your contract and method names.');
      } else {
        toast.error('An error occurred while registering the subdomain. Please try again later.');
      }
    }
  };



  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2, // Ensure this is higher than the background
      position: 'relative',
    }}>
      <Tooltip title={!signer || !inputValue ? "Please connect your wallet and enter a subdomain" : ""}>
        <StyledInput
          placeholder="Enter your desired subdomain"
          variant="outlined"
          value={inputValue}
          // disabled={!signer}
          title={!signer ? "Please connect your wallet" : ""}
          onChange={handleInputChange}
          InputProps={{
            style: { color: 'white' }
          }}
          // slotProps={{
          //   input: {
          //     startAdornment: <InputAdornment position="start">{price? price.concat('eth') : ''}</InputAdornment>,
          //   },
          // }}
          endAdornment={<InputAdornment position="end">.bullrun.eth</InputAdornment>}
        />
      </Tooltip>
      <StyledButton
        variant="contained"
        onClick={handleRegistration}
        disabled={!inputValue || !price || loading}
      >
        {loading ? <>Checking {' '} <CircularProgress size={24} /></> : 'Get it'}
      </StyledButton>
      <Box
        sx={{
          color: 'white',
          fontSize: '1.5rem',
          marginLeft: '10px',
          display: inputValue === '' || loading ? 'none' : 'inline', // Hide if inputValue is '0'
        }}
      >
        {price ? price : ''} ETH
      </Box>
    </Box>
  );
};

export default SearchBox;

