import React from 'react';
import { motion } from 'framer-motion';
import { Typography, Box } from '@mui/material';
import DynamicBackground from './DynamicBackground';
import SearchBox from './SearchBox';

const Hero = ({ signer }) => {
  const [word, setWord] = React.useState('');

  return (
    <Box className="hero" sx={{ 
      position: 'relative', 
      width: '100%', 
      height: '100vh', 
      overflow: 'hidden', 
      bgcolor: '#0a0a1e', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <DynamicBackground setWord={setWord} />
      <Box sx={{ 
        position: 'relative', 
        zIndex: 1,
        textAlign: 'center', 
        width: '100%',
        padding: '20px',
      }}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
            Claim Your .bullrun.eth Subdomain
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ color: 'white', mb: 4, textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}>
            Join the future of Web3 identity
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <SearchBox
            setWord={setWord}
            word={word}
            signer={signer}
            />
        </motion.div>
      </Box>
    </Box>
  );
};

export default Hero;