import React from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import MetaMaskLogin from './components/MetaMaskLogin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00aaff',
    },
    secondary: {
      main: '#ff00aa',
    },
    background: {
      default: '#0a0a1e',
      paper: '#1a1a2e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
  },
});

const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  width: '350px',
  padding: '10px',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],

  // Custom styling for the toast content
  '& .Toastify__toast-body': {
    display: 'flex',
    alignItems: 'center',
  },

  // Styling for the close button
  '& .Toastify__close-button': {
    color: theme.palette.error.main,
  },

  // Override the default progress bar color
  '& .Toastify__progress-bar': {
    background: theme.palette.primary.main,
  },
}));


function App() {
  const [signer, setSigner] = React.useState(null);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <MetaMaskLogin setSigner={setSigner} signer={signer} />
        <Hero signer={signer} />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> {/* Reduced margin top and bottom */}
          <Features />
        </Container>
        <Footer />
        <StyledToastContainer />
        
      </Box>
    </ThemeProvider>
  );
}

export default App;